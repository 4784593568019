<template>
  <AnalyticsCookieReceiver id="analytics-cookie-receiver" />
  <Toast position="top-center" />
  <ThemeProvider :theme="theme">
    <router-view />
  </ThemeProvider>
</template>

<script setup lang="ts">
import 'primeicons/primeicons.css'
import 'floating-vue/dist/style.css'
import { ref, provide, onMounted } from 'vue'
import Toast from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import componentsTheme from './components/theme'
import { ThemeProvider } from '@/components/utils/vue-sx'
import { translate } from '@/translations/question-translations'
import AnalyticsCookieReceiver from '@/hooks/AnalyticsCookieReceiver.vue'

import { useGlobalStore } from '@/stores/global'
import { useTokenStore } from '@/stores/token'
import { useUserStore } from '@/stores/user'
import { useSessionCacheStore } from '@/stores/sessionCache'
import { ToastService } from '@/services/toastService'
import { getTs } from '@/i18n'

const globalStore = useGlobalStore()

const theme = ref({
  ...componentsTheme,
})

const __translate = (str: string, params: any) => {
  const languageCode = globalStore.questionLanguage
  if (str === ':total poäng') {
    const { tt } = getTs(languageCode)
    return tt('content.marks.short', { count: params.total })
  }
  return translate(languageCode, str)
}

const toastService = new ToastService(useToast())
provide('toast', toastService)

window.K$ = { __: __translate }

onMounted(async () => {
  const tokenStore = useTokenStore()
  const userStore = useUserStore()
  const cacheStore = useSessionCacheStore()
  // token store must run fist to ensure we are in the correct mode
  await tokenStore.onReload()
  await userStore.onReload()
  await cacheStore.onReload()
})
</script>

<style lang="scss">
:root {
  /* FIXME: remove/replace usage of var(--theme-xxx) in all places */
  --theme-primary-cta: #0da057;
  --theme-primary-text: #373f41;
  --theme-white: #ffffff;

  box-sizing: border-box;
  font-family: 'Mulish', 'Helvetica', sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0px;
  overscroll-behavior: none;
  background-color: var(--p-blue-50);
}

a {
  text-decoration: none;
  color: var(--p-blue-500);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

button {
  font-family: inherit;
  font-size: inherit;
}

/* z-index global collection */
.p-dialog-mask {
  z-index: 2 !important;
}

.p-select-panel {
  z-index: 10 !important;
}

.p-popover {
  z-index: 1500 !important;
}

.spinner-overlay-mask {
  z-index: 2000 !important;
}

.p-scrolltop {
  z-index: 2500 !important;
}

.p-toast {
  z-index: 3000 !important;
  opacity: 1;
}

.p-menu {
  z-index: 5000 !important;
}

.p-tooltip {
  z-index: 6000 !important;
}

.p-drawer-mask {
  z-index: 10001 !important;
}

nav.vertical {
  z-index: 10000 !important;
}

// Toast
.p-toast-message-text {
  gap: 0px !important;
}

/* *:focus-visible {
  outline: rgb(39, 93, 197) auto 1px !important;
} */

/* form field invalid message color */
small.invalid {
  color: var(--p-inputtext-invalid-border-color);
}

/* ============= p-float-label ============= */

.p-floatlabel {
  .p-inputtext,
  .p-select-label,
  .p-autocomplete-input {
    padding-top: 18px;
    padding-bottom: 2px;
  }
}

.p-floatlabel input:focus ~ label,                          // for input
.p-floatlabel input.p-filled ~ label,
.p-floatlabel .p-inputwrapper-focus ~ label,                // for dropdown, password
.p-floatlabel .p-inputwrapper-filled ~ label,
.p-floatlabel .p-inputwrapper-focus .p-inputtext ~ label,   // for autocomplete
.p-floatlabel .p-inputwrapper-filled .p-inputtext ~ label {
  top: 6px !important;
  color: var(--p-floatlabel-color) !important;
}

/* label color when input is invalid (stay gray, not red) */
.p-floatlabel > .p-invalid + label {
  color: var(--p-floatlabel-color) !important;
}

/* ============= primevue/drawer ============= */

.p-drawer .p-drawer-header {
  justify-content: flex-end;
  padding: 16px;
}

.p-drawer {
  width: 100% !important;
  max-width: 320px;

  .p-drawer-content {
    padding: 0 16px 16px 16px;
  }
}
</style>
